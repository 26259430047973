import React from "react"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ShowCase = ({ section }) => (
  <div className="container mx-auto my-24">
    <div className="grid grid-cols-12 gap-4 lg:gap-8 items-center">
      <div
        className={
          section.alignImageLeft
            ? "col-span-12 md:col-span-8 lg:col-span-5"
            : "col-span-12 md:col-span-8 lg:col-span-5 xl:col-start-2"
        }
      >
        {section.badge && (
          <span className="inline-block relative rounded-full py-1 px-3 bg-brand-500 text-white text-sm font-semibold mb-2">
            {section.badge}
          </span>
        )}
        <h2 className="heading">{section.heading}</h2>
        <p>{section.sub}</p>
        {section.action && (
          <Link to={section.action.link} className="btn-secondary mt-4">
            {section.action.text}
          </Link>
        )}
      </div>
      <div
        className={
          section.alignImageLeft
            ? "flex col-span-12 md:col-span-4 lg:col-span-6 lg:col-start-1 md:order-first"
            : "flex col-span-12 md:col-span-4 lg:col-span-6 lg:col-start-7"
        }
      >
        {section.videoLink && (
          <Video videoSrc={section.videoLink} videoTitle={section.title} />
        )}
        {section.image && !section.videoLink && (
          <GatsbyImage
            image={getImage(section.image.staticFile)}
            alt={section.image.alternativeText || section.heading}
            className="mx-auto"
          />
        )}
      </div>
    </div>
  </div>
)

export const ContainedShowCase = ({ section }) => (
  <div className="bg-brand-100 py-32 my-24">
    <div className="container mx-auto">
      <div className="grid grid-cols-12 gap-4 lg:gap-8 items-center">
        <div
          className={
            section.alignLeft
              ? "col-span-12 md:col-span-8 lg:col-span-4 lg:col-start-8"
              : "col-span-12 md:col-span-8 lg:col-span-4 lg:col-start-2"
          }
        >
          <div className="block w-24 bg-brand-500 h-1.5 text-center mb-3" />
          <h2 className="heading-xl">{section.heading}</h2>
          <p className="text-base lg:text-lg">{section.text}</p>
          {section.action && (
            <Link to={section.action.link} className="btn-secondary mt-4">
              {section.action.text}
            </Link>
          )}
        </div>
        <div
          className={
            section.alignLeft
              ? "flex col-span-12 md:col-span-4 lg:col-span-6 lg:col-start-1 md:order-first"
              : "flex col-span-12 md:col-span-4 lg:col-span-6 lg:col-start-7"
          }
        >
          {section.image && (
            <GatsbyImage
              image={getImage(section.image.staticFile)}
              alt={section.image.alternativeText || section.heading}
              className="mx-auto"
            />
          )}
        </div>
      </div>
    </div>
  </div>
)

export const Video = ({ videoSrc, videoTitle }) => (
  <div className="video">
    <div className="absolute w-full h-full top-0 left-0">
      <iframe
        width="100%"
        height="100%"
        src={videoSrc}
        title={videoTitle}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  </div>
)

export default ShowCase
