import React from "react"

import { graphql, Link, useStaticQuery } from "gatsby"

const CTA = () => {
  const data = useStaticQuery(graphql`
    query KongsbergCTAQuery {
      strapi {
        cta: kongsbergCta {
          action {
            id
            link
            text
          }
          description
          id
          title
        }
      }
    }
  `)

  const cta = data.strapi.cta

  return (
    <div className="max-w-full lg:max-w-5xl xl:max-w-7xl mx-auto px-0 lg:px-4">
      <div className="py-24 px-4 sm:px-16 my-24 bg-brand-500 text-white text-center w-full">
        <div className="max-w-4xl mx-auto">
          <h3 className="heading-xl">{cta.title}</h3>
          <p className="text-xl">{cta.description}</p>
          <Link
            to={cta.action ? cta.action.link : "/kontakt"}
            className="btn-secondary-white mt-4"
          >
            {cta.action?.title || "Kontakt os"}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CTA
