import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

const AreasGrid = ({ section }) => {
  return (
    <div className="container mx-auto my-24">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {section.areas.map((area, i) => (
          <Link
            key={i}
            to={`/${area.slug}`}
            className="block group bg-gray-100 relative overflow-hidden"
            style={{ lineHeight: 0 }}
          >
            {area.cover && (
              <GatsbyImage
                image={getImage(area.cover.staticFile)}
                alt={area.cover.alternativeText}
                objectFit="cover"
                className=" block m-0 transform transition-transform group-hover:scale-105"
                style={{ lineHeight: 0, width: "100%", height: "100%" }}
              />
            )}
            <div className="text-3xl font-serif text-white absolute bottom-6 left-6 py-0 mb-0">
              {area.name}
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default AreasGrid
