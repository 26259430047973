import React from "react"
import PropTypes from "prop-types"

const HeadingSection = ({ section }) => {
  return (
    <div className="container mx-auto my-24">
      <div className="block mx-auto w-16 bg-brand-500 h-1.5 text-center mb-3" />
      <div
        className={
          section.large
            ? "max-w-4xl mx-auto text-center"
            : "max-w-2xl mx-auto text-center"
        }
      >
        <h2 className={section.large ? "heading-xl" : "heading-lg"}>
          {section.heading}
        </h2>
        <p
          className={section.large ? "text-2xl" : ""}
          dangerouslySetInnerHTML={{
            __html: section.sub && section.sub.replace(/\n/g, "<br />"),
          }}
        />
      </div>
    </div>
  )
}
HeadingSection.propTypes = {
  section: PropTypes.shape({
    heading: PropTypes.string,
    sub: PropTypes.string,
    large: PropTypes.bool,
  }),
}

export default HeadingSection
