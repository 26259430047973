import React from "react"

import HeadingSection from "./heading"
import AreasGrid from "./areas"
import ShowCase, { ContainedShowCase, Video } from "./showcase"

const SectionRenderer = ({ sections }) => {
  return (
    <>
      {sections.map((section, i) => {
        switch (section.__typename) {
          case "Strapi_ComponentSectionsSimpleShowCase":
            return <ShowCase key={i} section={section} />
          case "Strapi_ComponentSectionsContainedShowCase":
            return <ContainedShowCase key={i} section={section} />
          case "Strapi_ComponentSectionsHeading":
            return <HeadingSection key={i} section={section} />
          case "Strapi_ComponentSectionsAreasGrid":
            return <AreasGrid key={i} section={section} />
          case "Strapi_ComponentSectionsFullWidthVideo":
            return (
              <Video key={i} videoSrc={section.videLink} videoTitle="Video" />
            )
          default:
            throw new Error("Section not implemented")
        }
      })}
    </>
  )
}

export default SectionRenderer
