import { graphql, Link, useStaticQuery } from "gatsby"
import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionRenderer from "../components/sections"

import CTA from "../components/cta"

const IndexPage = () => {
  //const { home } = data

  const {
    strapi: { home, tables },
  } = useStaticQuery(graphql`
    query HomeQuery {
      strapi {
        home: kongsbergHome {
          hero: Hero {
            showCases: ShowCases {
              lightText
              linkTo
              text
              image {
                id
                alternativeText
                staticFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 440
                      placeholder: BLURRED
                      aspectRatio: 1
                    )
                  }
                }
              }
            }
            subTitle
            title
          }
          tablesHeading
          sections {
            __typename
            ... on Strapi_ComponentSectionsHeading {
              large
              sub
              heading
            }
            ... on Strapi_ComponentSectionsSimpleShowCase {
              alignImageLeft
              badge
              bigger
              heading
              sub
              videoLink
              action {
                link
                text
              }
              image {
                id
                alternativeText
                staticFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
            ... on Strapi_ComponentSectionsContainedShowCase {
              id
              alignLeft
              heading
              text
              action {
                link
                text
              }
              image {
                id
                alternativeText
                staticFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
            ... on Strapi_ComponentSectionsFullWidthVideo {
              id
              videoLink
            }
          }
        }
        tables: kongsbergTables {
          slug
          name
          shortDescription
          maxSpeed
          maxMaterialSize
          maxAcceleration
          image {
            id
            alternativeText
            staticFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 440)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={home.hero.title} />

      <div className="bg-brand-100 relative">
        <div className="absolute z-0 top-0 square-float flex justify-center w-full h-full overflow-hidden">
          <div className="absolute square bg-brand-200" />
          <div className="absolute square bg-brand-50" />
        </div>

        <div className="container relative mx-auto z-10 pb-24">
          <div className="relative z-10 py-32 lg:py-40 text-center">
            <h1 className="max-w-3xl mx-auto heading-xl lg:text-8xl">
              {home.hero.title}
            </h1>
            <p className="max-w-2xl mx-auto text-2xl">{home.hero.subTitle}</p>
          </div>
        </div>
      </div>

      <div className="-mt-48 relative z-10">
        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
          {home.hero.showCases.map((showCase, i) => (
            <Link
              to={showCase.linkTo}
              key={i}
              className="group w-full h-80 bg-brand-100 text-brand-900 p-6 relative flex items-end overflow-hidden"
            >
              <GatsbyImage
                className="absolute w-full h-full left-0 top-0 z-0 transform transition-transform group-hover:scale-105"
                image={getImage(showCase.image.staticFile)}
                alt={showCase.image.alternativeText}
                objectFit="cover"
                style={{
                  lineHeight: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              />
              <h2
                className={
                  "heading-sm mb-0 z-10" +
                  (showCase.lightText ? " text-brand-50" : "")
                }
              >
                {showCase.text}
              </h2>
            </Link>
          ))}
        </div>
      </div>

      <SectionRenderer sections={home.sections} />

      <div className="container mx-auto my-32">
        <div className="block w-24 mx-auto bg-brand-500 h-1.5 text-center mb-3" />
        <h2 className="heading-xl text-center">{home.tablesHeading}</h2>
        <div className="mx-auto max-w-xl xl:max-w-full grid grid-cols-1 xl:grid-cols-3 gap-6">
          {tables.map((table, i) => (
            <div key={i} className="text-center">
              <GatsbyImage
                image={getImage(table.image.staticFile)}
                alt={table.image.alternativeText}
                objectFit="contain"
                className="w-full h-80"
              />
              <h3 className="heading mb-2 mt-4">
                {table.name.split(" ").slice(0, -1)}{" "}
                <span className="text-brand-500">
                  {table.name.split(" ").pop()}
                </span>
              </h3>
              <p className="mx-auto max-w-sm">{table.shortDescription}</p>

              <div className="grid grid-cols-3 mt-6">
                <div>
                  <div className="uppercase text-gray-500 font-semibold text-xs">
                    Maks. emne dim.
                  </div>
                  <div>{table.maxMaterialSize}</div>
                </div>
                <div>
                  <div className="uppercase text-gray-500 font-semibold text-xs">
                    Maks. hastighed
                  </div>
                  <div>{table.maxSpeed}</div>
                </div>
                <div>
                  <div className="uppercase text-gray-500 font-semibold text-xs">
                    Maks. acceleration
                  </div>
                  <div>{table.maxAcceleration}</div>
                </div>
              </div>

              <div className="mt-6">
                <Link to={`/${table.slug}`} className="btn-secondary">
                  Se mere om {table.name}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      <CTA />
    </Layout>
  )
}

export default IndexPage
